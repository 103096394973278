import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from '@components/Link';

import { isExternalHref } from '@util/url';
import { trackAnalyticsEvent } from '@util/analytics';

import ArrowRightIcon from '@icons/ArrowRight.svg';

import * as styles from './ArrowLink.module.css';

const ArrowLink = ({
  href,
  onClick,
  text,
  variant = 'default',
  trackingScope,
}) => {
  const className = cn(styles.root, {
    [styles.primary]: variant === 'primary',
  });

  const isExternalLink = isExternalHref(href);

  const handleClick = (e) => {
    if (trackingScope) {
      trackAnalyticsEvent('link', {
        scope: trackingScope,
        text: text,
        href,
      });
    }

    if (onClick) {
      onClick(e);
    }
  };

  const inner = (
    <>
      <span className={cn(styles.text)}>{text}</span>
      <ArrowRightIcon className={cn(styles.icon)} />
    </>
  );

  return href ? (
    <Link
      prefetch={false}
      href={href}
      className={className}
      target={isExternalLink ? '_blank' : undefined}
      rel={isExternalLink ? 'noopener noreferrer' : null}
      onClick={handleClick}
    >
      {inner}
    </Link>
  ) : (
    <button className={className} onClick={handleClick}>
      {inner}
    </button>
  );
};

ArrowLink.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'default']),
};

export default ArrowLink;
