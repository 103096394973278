import Head from 'next/head';
import { useRouter } from 'next/router';

import { joinPathsFactory } from '@util/url';
import { joinTitle } from '@util/seo';

import siteInformation from '@content/default.json';
import { urlForOgImage } from '@sanity-lib/image';

const SEO = ({
  title,
  description,
  ogTitle,
  ogDescription,
  ogSiteName,
  ogApplicationName,
  ogImage,
}) => {
  const { asPath } = useRouter();
  const joinPaths = joinPathsFactory(siteInformation.url);

  const meta = {
    title: joinTitle(siteInformation.title, title),
    description: description || siteInformation.seoFallbacks.description,
  };

  const canonical = joinPaths(asPath);

  const ogMeta = {
    title: ogTitle ?? meta.title,
    description: ogDescription ?? meta.description,
    image: ogImage
      ? typeof ogImage === 'string'
        ? ogImage
        : urlForOgImage(ogImage.asset)
      : siteInformation.seoFallbacks.image,
    type: 'website',
    url: canonical,
    siteName: ogSiteName ?? siteInformation.title,
    applicationName: ogApplicationName ?? siteInformation.title,
  };

  // Adding a key attribute to every SEO tag helps next.js to
  // avoid duplicate tags. This is useful, so the SEO component
  // can be used at any depth of the component tree, with the latest
  // instance overwriting the previous ones.
  //
  // This way it's easy to mount the SEO defaults at the root level
  // and have each page overwrite the tags it needs to overwrite.
  //
  // SEE: https://nextjs.org/docs/pages/api-reference/components/head
  return (
    <Head>
      <title key="title">{meta.title}</title>
      <link key="canonical" rel="canonical" href={canonical} />

      <link
        key="linkFavicon"
        sizes="any"
        type="image/svg+xml"
        rel="shortcut icon"
        href="/favicon.svg"
      />
      <link
        key="linkAppleTouchIcon"
        rel="apple-touch-icon"
        href="/images/apple-touch-icon.png"
      />
      <link
        key="linkMaskIcon"
        rel="mask-icon"
        href="/images/safari-icon.svg"
        color="#2A6FE1"
      ></link>

      <meta
        key="metaApplicationName"
        name="application-name"
        content={ogMeta.applicationName}
      />
      <meta
        key="metaMsApplicationTileColor"
        name="msapplication-TileColor"
        content="#ffffff"
      />
      <meta
        key="metaMsApplicationTileImage"
        name="msapplication-TileImage"
        content="/images/ms-icon.png"
      />

      <meta
        key="metaDescription"
        name="description"
        content={meta.description}
      />

      <meta key="metaOgTitle" property="og:title" content={ogMeta.title} />
      <meta
        key="metaOgDescription"
        property="og:description"
        content={ogMeta.description}
      />
      <meta key="megaOgImage" property="og:image" content={ogMeta.image} />
      <meta key="metaOgType" property="og:type" content={ogMeta.type} />
      <meta key="metaOgUrl" property="og:url" content={ogMeta.url} />
      <meta
        key="metaOgSiteName"
        property="og:site_name"
        content={ogMeta.siteName}
      />

      <meta
        key="metaTwitterCard"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        key="metaTwitterTitle"
        name="twitter:title"
        content={ogMeta.title}
      />
      <meta
        key="metaTwitterDescription"
        name="twitter:description"
        content={ogMeta.description}
      />
      <meta
        key="metaTwitterImage"
        name="twitter:image"
        content={ogMeta.image}
      />
      <meta
        key="metaTwitterSite"
        name="twitter:site"
        content={siteInformation.socialMedia.handles.twitter}
      />

      <meta
        key="metaViewport"
        name="viewport"
        content="width=device-width, initial-scale=1"
      />
    </Head>
  );
};

export default SEO;
