export const onConsent = (cb, scope) => {
  if (scope) {
    window.addEventListener('CookiebotOnAccept', () => {
      if (window.Cookiebot.consent[scope]) {
        cb();
      }
    });
  } else {
    window.addEventListener('CookiebotOnAccept', cb);
  }
};

export const onDecline = (cb) => {
  window.addEventListener('CookiebotOnDecline', cb);
};

export const hasConsent = (scope) => {
  if (scope) {
    return window.Cookiebot.consented && window.Cookiebot.consent[scope];
  }

  return window.Cookiebot.consented;
};
