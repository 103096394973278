import cn from 'classnames';
import { useRouter } from 'next/router';

import ArrowLink from '@components/ArrowLink';
import { previewDisableUrl } from '@sanity-defs/env';

import styles from './PreviewBanner.module.css';

export default function PreviewBanner() {
  const router = useRouter();

  const buttonHandler = async () => {
    try {
      await fetch(previewDisableUrl, {
        method: 'GET',
      });
    } finally {
      router.reload();
    }
  };

  return (
    <div className={cn('bodySmall', styles.root)}>
      <p className={styles.paragraph}>
        You are in preview mode.
        <ArrowLink onClick={buttonHandler} text="Exit Preview" />
      </p>
    </div>
  );
}
