import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// As we're delibaretly wrapping around next/link, we're ignoring our custom
// eslint rule here
// eslint-disable-next-line local-rules/use-custom-link
import Link from 'next/link';
import { useRouter } from 'next/router';

const paramsToPersist = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'mid',
  'gclid',
  'source',
];

const fileExtensions = ['.pdf', '.png', '.jpg', '.zip'];

/**
 * A simple wrapper around next/link to ensure that certain query
 * params are passed along to the next page when navigating.
 */
const TrackedLink = ({ children, href: _href, ...rest }) => {
  const [persisted, setPersisted] = useState({});
  const router = useRouter();

  const href = _href.pathname ?? _href;
  const query = _href.query ?? {};

  // If the link is a file, we want to opt out of using
  // next.js’ Link comoonent, as it will try to prefetch
  // and can potentially result in a 404
  const isFile = fileExtensions.some((ext) => href.endsWith(ext));

  // Grab and update params in useEffect in order not to have
  // hydration warnings between useRouter responses.
  useEffect(() => {
    // Don’t append query params to mailto links
    if (href.startsWith('mailto')) return;

    const params = {};
    for (const key in router.query) {
      if (paramsToPersist.includes(key)) {
        params[key] = router.query[key];
      }
    }
    setPersisted(params);
  }, [href, router]);

  return isFile ? (
    <a href={href} {...rest}>
      {children}
    </a>
  ) : (
    <Link
      href={{
        pathname: href,
        query: { ...query, ...persisted },
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};

TrackedLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TrackedLink;
