import defaultContent from '@content/default.json';

/**
 * Joins fragments of a path together, ensuring there is a single slash between each fragment.
 * Optionally also ensures there is a trailing slash.
 */
export const joinPathsFactory =
  (base) =>
  (path = '', trailingSlash = false) => {
    const inputPaths = [base, path].filter(Boolean);
    const joined = `${inputPaths.join('/')}${trailingSlash ? '/' : ''}`;
    return joined.replace(/([^:]\/)\/+/g, '$1');
  };

/**
 * Checks if a href should be treated as an external resource.reverse();
 *
 * @param{string|object|null} href
 * @returns Boolean
 */
export const isExternalHref = (_href) => {
  const href = (_href && _href.pathname) ?? _href ?? null;

  if (!href) return false;
  if (!href.startsWith('http')) return false;

  const { knownExternalLinks } = defaultContent;

  for (const knownLink of knownExternalLinks) {
    if (href.includes(knownLink)) return false;
  }

  return true;
};
