import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Cookiebot = ({ domainGroupId }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (!domainGroupId || !document) return;
    if (hasLoaded) return;
    const script = document.createElement('script');
    script.setAttribute('id', 'Cookiebot');
    script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
    script.setAttribute('data-cbid', domainGroupId);
    script.setAttribute('data-blockingmode', 'none');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', 'true');
    script.setAttribute('defer', 'true');
    script.onload = () => {
      setHasLoaded(true);
    };
    document.body.appendChild(script);
  }, [domainGroupId, hasLoaded]);

  return hasLoaded ? (
    <script
      id="CookieDeclaration"
      src={`https://consent.cookiebot.com/${domainGroupId}/cd.js`}
      type="text/javascript"
      async
    ></script>
  ) : null;
};

Cookiebot.propTypes = {
  domainGroupId: PropTypes.string.isRequired,
};

export default Cookiebot;
