import { useEffect, useState } from 'react';
import Head from 'next/head';
import { SpeedInsights } from '@vercel/speed-insights/next';

import fontFaceVariables from '@assets/fonts';
import { spritePath } from '@assets/logos';

import 'sanitize.css';
import '@styles/variables.css';
import '@styles/globals.css';

import SEO from '@components/SEO';
import Cookiebot, { onConsent, onDecline } from '@components/Cookiebot';
import PreviewBanner from '@components/preview/PreviewBanner';

export default function MyApp({ Component, pageProps }) {
  const [, setIsConsent] = useState(false);

  const { draftMode = false } = pageProps;

  // Automatically grab the seo data from Sanity content
  // This means all sanity content needs to be named "content" as a prop
  const seo = pageProps.content?.seo;

  useEffect(() => {
    onConsent(() => setIsConsent(true), 'marketing');
    onDecline(() => setIsConsent(false));
  }, []);

  return (
    <>
      <Head>
        <link rel="preload" href={spritePath} as="image" />
      </Head>
      <SEO {...seo} />
      <style jsx global>
        {fontFaceVariables}
      </style>

      {draftMode && <PreviewBanner />}
      <Component {...pageProps} />
      <Cookiebot domainGroupId="1e997ab1-0ba9-4b4f-907b-731009c6e1a7" />
      <SpeedInsights />
    </>
  );
}
