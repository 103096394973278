import localFont from 'next/font/local';

// TODO: Replace with the real font files
const replica = localFont({
  src: './ReplicaLLSub-Regular.woff2',
  fallback: ['system-ui', 'sans-serif'],
});

const replicaMono = localFont({
  src: './ReplicaMonoLLSub-Regular.woff2',
  fallback: ['monospace'],
});

const mdSystem = localFont({
  src: [
    {
      path: './MDSystem-Regular.woff2',
      weight: '400',
    },
    {
      path: './MDSystem-Semibold.woff2',
      weight: '600',
    },
  ],
  fallback: ['system-ui', 'sans-serif'],
});

const inlineStyles = `
  :root {
    --font-family-body: ${mdSystem.style.fontFamily};
    --font-family-headline: ${replica.style.fontFamily};
    --font-family-mono: ${replicaMono.style.fontFamily};
  }
`;

export default inlineStyles;
