export const apiVersion =
  process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2024-02-22';

export const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET;
export const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;
export const readToken = process.env.NEXT_PUBLIC_SANITY_READ_TOKEN;
export const useCdn = false;

export const sanityStudioUrl = '/admin';
export const previewEnableUrl = '/api/draft';
export const previewDisableUrl = '/api/disable-draft';
