export const trackAnalyticsEvent = (name, metadata) => {
  if (window.sa_event) {
    window.sa_event(name, {
      ...metadata,
      path: window.location.pathname,
    });
  }
  if (window.dataLayer) {
    window.dataLayer.push({
      event: name,
      ...metadata,
      path: window.location.pathname,
    });
  }
};
