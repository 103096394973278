/**
 * Joins title with base title.
 * If the title already includes the base title, it will not be added again.
 */
export const joinTitle = (base, title) => {
  if (title && title.includes(base)) {
    return title;
  }

  if (title) {
    return `${title} | ${base}`;
  }

  return base;
};
